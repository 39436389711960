export class CarRequest {
    constructor(
        public plateNumber: string = '',
        public vehicleType: string = '',
        public rentingCompany: string = '',
        public withTyreService: boolean = true,
        public phoneNumber: string = '',
        public email: string = '',
        public fleetContactPhoneNumber: string = '',
        public fleetContactEmail: string = '',
        public notificationEmail: string = '',
        public assistanceMarsoLeasing: string = '',
        public assistanceBrand: string = '',
        public assistanceBrandAbroad: string = '',
    ) {}
}
