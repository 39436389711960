<template>
    <div class="w-full 2xl:container mx-auto p-4 flex flex-col">
        <div class="filters justify-between lg:items-end">
            <div class="flex flex-col md:flex-row gap-4">
                <button class="btn btn-rounded btn-primary h-[25px]" @click="openCreateCarModal()">
                    {{ trans('new_car_registration', 'new car registration', 'car_data_maintenance') }}
                </button>
                <button class="btn btn-rounded btn-primary h-[25px]" @click="openUploadCarsModal()">
                    {{ trans('mass_upload', 'mass upload', 'car_data_maintenance') }}
                </button>
            </div>
        </div>
        <responsive-table
            v-model:fields="carDataFields"
            :items="carStore.cars"
            striped
            condensed
            panel
            :isBusy="carStore.loading"
            v-model:sortBy="sortBy"
            v-model:sortDesc="sortDesc"
            pagination
            v-model:currentPage="page"
            v-model:itemsPerPage="itemsPerPage"
            :totalItems="carStore.totalCars"
        >
            <template #cell-plateNumber="{ item: carData }">
                <div class="text-blue cursor-pointer" @click="openUpdateCarModal(carData as CarInterface)">
                    {{ (carData as CarInterface).plateNumber }}
                </div>
            </template>
            <template #cell-tyreService="{ item: carData }">
                <div>
                    {{
                        (carData as CarInterface).withTyreService
                            ? trans('yes', 'Yes', 'car_data_maintenance')
                            : trans('no', 'No', 'car_data_maintenance')
                    }}
                </div>
            </template>
        </responsive-table>
        <modal
            :isOpen="carFormModalIsOpen"
            :title="
                selectedCar !== null
                    ? trans('edit_car_modal_title', 'Edit Car: { plateNumber }', 'car_data_maintenance', {
                          plateNumber: selectedCar.plateNumber,
                      })
                    : trans('add_car_modal', 'Add Car', 'car_data_maintenance')
            "
            @close="carFormModalIsOpen = false"
        >
            <alert
                :title="trans('save_error', 'Save error', 'car_data_maintenance')"
                :message="errorMessage"
                v-if="errorMessage.length"
            />
            <div class="p-4">
                <div class="font-bold text-gray-darkest uppercase border-gray border-b-1 pb-2">
                    {{ trans('enter_car_data', 'Enter car data', 'car_data_maintenance') }}
                </div>
                <div class="flex flex-col lg:gap-4 lg:flex-row">
                    <shop-input
                        :label="trans('plate_number', 'Plate Number', 'car_data_maintenance')"
                        required
                        v-model="carRequest.plateNumber"
                        :valid="!isBlank(carRequest.plateNumber)"
                        :show-validity="true"
                        class="my-5"
                        border
                    />
                    <shop-input
                        :label="trans('vehicle_type', 'Vehicle Type', 'car_data_maintenance')"
                        required
                        v-model="carRequest.vehicleType"
                        :valid="!isBlank(carRequest.vehicleType)"
                        :show-validity="true"
                        class="my-5"
                        border
                    />
                    <shop-input
                        :label="trans('renting_company', 'Renting Company', 'car_data_maintenance')"
                        required
                        v-model="carRequest.rentingCompany"
                        :valid="!isBlank(carRequest.rentingCompany)"
                        :show-validity="true"
                        class="my-5"
                        border
                    />
                    <double-row-toggle
                        :label="trans('with_tyre_service', 'With Tyre Service', 'car_data_maintenance')"
                        v-model="carRequest.withTyreService"
                    />
                </div>
            </div>
            <div class="bg-gray p-4">
                <div class="font-bold text-gray-darkest uppercase border-gray-darkest border-b-1 pb-2">
                    {{ trans('enter_customer_data', 'Enter customer data', 'car_data_maintenance') }}
                </div>
                <div class="flex flex-col lg:gap-4 lg:flex-row">
                    <shop-input
                        :label="trans('phone_number', 'Phone Number', 'car_data_maintenance')"
                        required
                        v-model="carRequest.phoneNumber"
                        class="my-5"
                        border
                    />

                    <shop-input
                        :label="trans('email', 'E-mail', 'car_data_maintenance')"
                        required
                        v-model="carRequest.email"
                        class="my-5"
                        border
                    />
                </div>
            </div>
            <div class="p-4">
                <div class="font-bold text-gray-darkest uppercase border-gray border-b-1 pb-2">
                    {{ trans('enter_contact_information', 'Enter contact information', 'car_data_maintenance') }}
                </div>
                <div class="flex flex-col lg:gap-4 lg:flex-row">
                    <shop-input
                        :label="trans('phone_number', 'Phone Number', 'car_data_maintenance')"
                        required
                        v-model="carRequest.fleetContactPhoneNumber"
                        :valid="!isBlank(carRequest.fleetContactPhoneNumber)"
                        :show-validity="true"
                        class="my-5"
                        border
                    />
                    <shop-input
                        :label="trans('email', 'E-mail', 'car_data_maintenance')"
                        required
                        v-model="carRequest.fleetContactEmail"
                        :valid="!isBlank(carRequest.fleetContactEmail)"
                        :show-validity="true"
                        class="my-5"
                        border
                    />
                    <shop-input
                        :label="trans('notification_email', 'Notification E-mail', 'car_data_maintenance')"
                        :disabled="true"
                        v-model="carRequest.notificationEmail"
                        class="my-5"
                        border
                    />
                </div>
            </div>
            <div class="bg-gray p-4">
                <div class="font-bold text-gray-darkest uppercase border-gray-darkest border-b-1 pb-2">
                    {{ trans('enter_assistance_data', 'Enter assistance data', 'car_data_maintenance') }}
                </div>
                <div class="flex flex-col lg:gap-4 lg:flex-row">
                    <shop-input
                        :label="trans('marso_leasing', 'Marso Leasing', 'car_data_maintenance')"
                        required
                        v-model="carRequest.assistanceMarsoLeasing"
                        class="my-5"
                        border
                    />
                    <shop-input
                        :label="trans('brand_national', 'Brand - National', 'car_data_maintenance')"
                        required
                        v-model="carRequest.assistanceBrand"
                        class="my-5"
                        border
                    />
                    <shop-input
                        :label="trans('brand_abroad', 'Brand - Abroad', 'car_data_maintenance')"
                        required
                        v-model="carRequest.assistanceBrandAbroad"
                        class="my-5"
                        border
                    />
                </div>
            </div>
            <div class="p-4">
                <div class="font-bold text-gray-darkest uppercase border-gray border-b-1 pb-2">
                    {{ trans('green_card_upload', 'Green card upload', 'car_data_maintenance') }}
                </div>
                <file-input class="mt-3" />
            </div>
            <div class="pt-2 border-t-1 border-gray flex justify-end">
                <button class="btn btn-rounded btn-success" @click="selectedCar === null ? createCar() : updateCar()">
                    <i class="marso-icon-check"></i>
                    {{ trans('save', 'Save', 'car_data_maintenance') }}
                </button>
            </div>
        </modal>
        <modal
            :isOpen="uploadCarsModalIsOpen"
            :title="trans('upload_cars_modal', 'Upload Cars', 'car_data_maintenance')"
            @close="uploadCarsModalIsOpen = false"
        >
            <alert
                :title="trans('save_error', 'Save error', 'car_data_maintenance')"
                :message="errorMessage"
                v-if="errorMessage.length"
            />
            <div>
                {{
                    trans(
                        'upload_manual',
                        'First download the sample csv and fill it, then upload back! Dont delete the header of the CSV!',
                        'car_data_maintenance'
                    )
                }}
                <a class="font-bold underline" target="_blank" href="/cars/cars_sample.csv">
                    {{ trans('cars_sample_csv', 'Download Cars Sample CSV', 'car_data_maintenance') }}
                </a>
            </div>
            <file-input
                class="mt-3"
                accept="text/csv"
                placeholder="cars.csv"
                v-model="uploadedCsv"
                :label="trans('upload_csv', 'Upload CSV', 'car_data_maintenance')"
            />
            <div class="mt-4 pt-4 border-t-1 border-gray flex justify-end">
                <button class="btn btn-rounded btn-success" @click="uploadCsv()">
                    <i class="marso-icon-check"></i>
                    {{ trans('upload', 'Upload', 'car_data_maintenance') }}
                </button>
            </div>
        </modal>
    </div>
</template>

<script setup lang="ts">
import ShopInput from '../../components/Form/ShopInput.vue';
import ResponsiveTable from '../../components/ResponsiveTable/ResponsiveTable.vue';
import type FieldDefinitionInterface from '../../components/ResponsiveTable';
import { ref, type ComputedRef, type Ref, computed, watchEffect } from 'vue';
import { trans } from '../../common/i18n';
import { useCarStore } from '../../stores/car/carStore';
import { useRouter } from 'vue-router';
import type RequestObject from '@/routes/RequestObject';
import { useToastStore } from '../../stores/toast/toastStore';
import type ServerError from '@/common/error/ServerError';
import type CarInterface from '@/models/Car';
import { CarRequest } from '@/stores/car/CarRequest';
import Modal from '../../components/Modal.vue';
import { FileInput } from '../../components/Form';
import { DoubleRowToggle } from '../../components/Form';
import Alert from '../../components/Alert.vue';
import { ToastTypeEnum } from '@/stores/toast/ToastInterface';
import { isBlank } from "../../common/utils/validators";

const router = useRouter();
const carStore = useCarStore();
const sortBy: Ref<string> = ref<string>('id');
const carRequest: Ref<CarRequest> = ref(new CarRequest());
const toastStore = useToastStore();
const sortDesc: Ref<boolean> = ref<boolean>(true);
const page: Ref<number> = ref<number>(1);
const itemsPerPage: Ref<number> = ref<number>(25);
const uploadedCsv: Ref<File | null> = ref<File | null>(null);
const errorMessage: Ref<string> = ref<string>('');
const carFormModalIsOpen: Ref<boolean> = ref<boolean>(false);
const uploadCarsModalIsOpen: Ref<boolean> = ref<boolean>(false);
const selectedCar: Ref<CarInterface | null> = ref<CarInterface | null>(null);
const requiredFields: string[] = ['plateNumber', 'vehicleType', 'rentingCompany', 'fleetContactPhoneNumber', 'fleetContactEmail'];
const carDataFields: Ref<FieldDefinitionInterface[]> = ref<FieldDefinitionInterface[]>([
    {
        key: 'plateNumber',
        label: trans('plate_number', 'Plate Number', 'car_data_maintenance') ?? 'Plate Number',
        value: 'plateNumber',
        sortable: true,
        filterable: true,
    },
    {
        key: 'vehicleType',
        label: trans('vehicle_type', 'Vehicle Type', 'car_data_maintenance') ?? 'Vehicle Type',
        value: 'vehicleType',
        sortable: true,
        filterable: true,
    },
    {
        key: 'rentingCompany',
        label: trans('renting_company', 'Renting Company', 'car_data_maintenance') ?? 'Renting Company',
        value: 'rentingCompany',
        sortable: true,
        filterable: true,
    },
    {
        key: 'tyreService',
        label: trans('with_tyre_service', 'With Tyre Service', 'car_data_maintenance') ?? 'With Tyre Service',
        value: 'tyreService',
        sortable: false,
        filterable: false,
    },
]);

const requestObject: ComputedRef<RequestObject> = computed(() => {
    const filters: { [key: string]: string } = {};
    carDataFields.value.forEach((field: FieldDefinitionInterface) => {
        if (field.filterable && field.filterValue) {
            filters[field.key] = field.filterValue;
        }
    });

    let order = `order[${sortBy.value}]`;

    let requestObject: RequestObject = {
        page: page.value,
        itemsPerPage: itemsPerPage.value,
        [order]: sortDesc.value ? 'desc' : 'asc',
        ...filters,
    };

    return requestObject;
});

const openUploadCarsModal = (): void => {
    errorMessage.value = '';
    uploadCarsModalIsOpen.value = true;
};

const openCreateCarModal = (): void => {
    errorMessage.value = '';
    carRequest.value = new CarRequest();
    selectedCar.value = null;
    carFormModalIsOpen.value = true;
};

const openUpdateCarModal = (car: CarInterface): void => {
    errorMessage.value = '';
    selectedCar.value = car;
    carRequest.value = new CarRequest(
        car.plateNumber,
        car.vehicleType,
        car.rentingCompany,
        car.withTyreService,
        car.phoneNumber,
        car.email,
        car.fleetContactPhoneNumber,
        car.fleetContactEmail,
        car.notificationEmail,
        car.assistanceMarsoLeasing,
        car.assistanceBrand,
        car.assistanceBrandAbroad
    );

    carFormModalIsOpen.value = true;
};

const uploadCsv = () => {
    const formData: FormData = new FormData();
    errorMessage.value = '';

    if (uploadedCsv.value !== null) {
        formData.append('csv', uploadedCsv.value);
    }

    carStore
        .uploadCarCsv(router, formData)
        .then(() => {
            uploadedCsv.value = null;
            uploadCarsModalIsOpen.value = false;
            page.value = 1;
            carStore.getCars(router, requestObject.value).catch((error: ServerError) => {
                toastStore.addErrorToast(error.toString());
            });
            toastStore.addToast({
                message: trans('cars_created_successfully', 'Cars created successfully!', 'car_data_maintenance'),
                type: ToastTypeEnum.Success,
                timer: 5,
            });
        })
        .catch((error: ServerError) => {
            toastStore.addErrorToast(error.toString());
        });
};

const createCar = () => {
    errorMessage.value = '';

    if(!checkRequiredFields(requiredFields, carRequest.value as any)) {
        toastStore.addToast({
            message: trans('fill_required_fields', 'Please fill in all required fields.', 'car_data_maintenance'),
            type: ToastTypeEnum.Warning,
            timer: 5,
        });
        return;
    }
    carStore
        .createCar(router, carRequest.value)
        .then(() => {
            carFormModalIsOpen.value = false;
            page.value = 1;
            carStore.getCars(router, requestObject.value).catch((error: ServerError) => {
                toastStore.addErrorToast(error.toString());
            });
            toastStore.addToast({
                message: trans('car_created_successfully', 'Car created successfully!', 'car_data_maintenance'),
                type: ToastTypeEnum.Success,
                timer: 5,
            });
        })
        .catch((error: ServerError) => {
            toastStore.addErrorToast(error.toString());
        });
};

const updateCar = () => {
    errorMessage.value = '';

    if(!checkRequiredFields(requiredFields, carRequest.value as any)) {
        toastStore.addToast({
            message: trans('fill_required_fields', 'Please fill in all required fields.', 'car_data_maintenance'),
            type: ToastTypeEnum.Warning,
            timer: 5,
        });
        return;
    }

    if (selectedCar.value === null) {
        errorMessage.value = trans('car_not_selected', 'Car not selected', 'car_data_maintenance');
        return;
    }

    carStore
        .updateCar(router, carRequest.value, selectedCar.value.id)
        .then(() => {
            carFormModalIsOpen.value = false;
            page.value = 1;
            toastStore.addToast({
                message: trans('car_updated_successfully', 'Car updated successfully!', 'car_data_maintenance'),
                type: ToastTypeEnum.Success,
                timer: 5,
            });
            carStore.getCars(router, requestObject.value).catch((error: ServerError) => {
                toastStore.addErrorToast(error.toString());
            });
        })
        .catch((error: ServerError) => {
            toastStore.addErrorToast(error.toString());
        });
};

const checkRequiredFields = (fields: string[], values: Record<string, string | undefined>) =>
  fields.every(field => values[field]);

watchEffect(() => {
    carStore.getCars(router, requestObject.value).catch((error: ServerError) => {
        toastStore.addErrorToast(error.toString());
    });
});
</script>
